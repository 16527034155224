import { Controller } from "@hotwired/stimulus"
import { datepickerMixins } from "./mixins/datepicker";
import moment from "moment";
import { post,get } from '@rails/request.js'
import HotelDatepicker from "hotel-datepicker";
import "hotel-datepicker/dist/css/hotel-datepicker.css";

// Connects to data-controller="property-inline-datepicker"
export default class extends Controller {
  static outlets = ["property-datepicker", "property"]

  static values = {
    identifier: String,
    checkin: String,
    checkout: String,
    checkInSelectTitleMessage: String,
    checkInSelectSubHeaderMessage: String,
    checkInSelectTitleChangeMessage: String,
    checkInSelectSubHeaderChangeMessage: String,
    region: String,
    availabilityWindow: Number,
  }

  static targets = [
      "inlineDatepicker",
      "inlineSummaryDatesTitle",
      "inlineSummaryDates"
  ]

  connect() {
    this.min_datepicker_range                       = moment();
    this.max_datepicker_range                       = moment().add(12, 'M');

    this.checkinField                               = document.querySelector("#property_checkin")
    this.checkoutField                              = document.querySelector("#property_checkout")

    // Init mixins
    datepickerMixins(this);

    // initializations
    this.init();
    this.fromPropertyDatepicker = false
  }

  getJSON(url) {
      return fetch(url).then(response => response.json());
  }

  init() {
    if (!this.hasInlineDatepickerTarget) return

    Promise.all([
        this.getJSON("/feeds/invalid-check-in-dates/" + this.identifierValue),
        this.getJSON("/feeds/invalud-check-out-dates/" + this.identifierValue),
        this.getJSON("/feeds/check-out-minimum-stays/" + this.identifierValue),
        this.getJSON("/feeds/calendar-colors/" + this.identifierValue),
    ]).then(([check_in_invalid_dates, check_out_invalid_dates, check_out_minimum_stays, calendar_colors]) => {

      this.check_in_invalid_dates   = check_in_invalid_dates
      this.check_out_invalid_dates  = check_out_invalid_dates
      this.check_out_minimum_stays  = check_out_minimum_stays
      this.calendar_colors          = calendar_colors

      this.inline_datepicker = mobiscroll.datepicker(this.inlineDatepickerTarget, {
        themeVariant: 'light',
        theme: "material",
        controls: ['calendar'],
        select: 'range',
        display: 'inline',
        calendarType: 'month',
        dateFormat: 'YYYY-MM-DD',
        returnFormat: 'moment',
        pages: 2,
        min: this.min_datepicker_range,
        showRangeLabels: true,
        inRangeInvalid: false,
        rangeEndInvalid: true,
        invalid: this.check_in_invalid_dates,
        colors: this.calendar_colors,
        onTempChange: (event, inst) => {
            this.check_out_state(event, inst)
            this.both_selected_state(event, inst)
        },
        renderDayContent: (date, selected, events) => {
          const html = document.createElement('div');
          [this.date_is_checkout_only, /* add more methods here */].forEach(method => {
            const content = method.call(this, date, selected, events);
            if (content) html.appendChild(content);
          });
          return html;
        }
      })
    })
  }

  date_is_checkout_only(date, selected, events) {
    let html            = null
    const ariaLabel     = moment(date.date).format('dddd, MMMM D, YYYY')
    const selectedCell  = document.querySelector(`div.mbsc-calendar-cell-text[aria-label="${ariaLabel}"]`)

    if(selectedCell){
        let container      = selectedCell.closest('.mbsc-calendar-cell')
        const classes      = container.classList
        const minDaysClass = Array.from(classes).find(className => className.startsWith('min-days'))

        if (minDaysClass) {
            let tipCloud       = document.createElement('div')
            tipCloud.classList.add('tip-cloud')

            let minDays        = minDaysClass.split('-').pop()
            tipCloud.textContent = minDays

            html = tipCloud
        }
    }

    return html
  }

  both_selected_state(event, inst) {
    if (event.value[0] && event.value[1]) {
      inst.setOptions({ invalid: this.check_in_invalid_dates })

      this.set_summary_messages(event)
    } else return
  }

  check_out_state(event, inst) {
    if (event.value[0] && !event.value[1]) {

        const check_in      = moment(event.value[0]).format('YYYY-MM-DD')
        this.minimum_stay   = this.check_out_minimum_stays[check_in].length || 1

        console.log(this.check_out_minimum_stays)
        console.log(this.check_out_minimum_stays[check_in])

        inst.setOptions({ minRange: this.minimum_stay, maxRange: this.maximum_stay })

        this.set_summary_messages(event)
    } else return
  }

  set_summary_messages(event) {
        // Change summary messages based on datepicker values
        if(event.value[0] && !event.value[1]){
          this.inlineSummaryDatesTitleTarget.textContent    = this.checkInSelectTitleChangeMessageValue
          this.inlineSummaryDatesTarget.textContent         = this.checkInSelectSubHeaderChangeMessageValue
          this.inlineSummaryDatesTarget.textContent         = "Minimum stay: " + (this.minimum_stay) + " nights"

        } else if(event.value[0] && event.value[1]) {

          const nights = moment(event.value[1]).diff(moment(event.value[0]), 'days')
          this.inlineSummaryDatesTitleTarget.textContent    = nights + " nights in " + this.regionValue

          const checkin_format                              = (moment(event.value[0]).format('MMM DD, YYYY'))
          const checkout_format                             = (moment(event.value[1]).format('MMM DD, YYYY'))

          this.inlineSummaryDatesTarget.textContent         = checkin_format + " - " + checkout_format

        } else {
          this.inlineSummaryDatesTitleTarget.textContent    = this.checkInSelectTitleMessageValue
          this.inlineSummaryDatesTarget.textContent         = this.checkInSelectSubHeaderMessageValue
        }
  }

  clear_datepickers() {
          // Reset the values of the datepicker and inline datepicker
//          if(this.hasPropertyDatepickerOutlet) {
//            this.propertyDatepickerOutlet.datepicker.setVal();
//            this.propertyDatepickerOutlet.datepickerTarget.value = "";
//            this.propertyDatepickerOutlet.checkinTarget.value = "";
//            this.propertyDatepickerOutlet.checkoutTarget.value = "";
//            this.propertyDatepickerOutlet.get_booking_details()
//          }

          this.inline_datepicker.setOptions({ invalid: this.check_in_invalid_dates })
          this.inline_datepicker.setVal();

          // Reset the summary messages
          this.inlineSummaryDatesTitleTarget.textContent  = this.checkInSelectTitleMessageValue;
          this.inlineSummaryDatesTarget.textContent       = this.checkInSelectSubHeaderMessageValue;
  }
}
